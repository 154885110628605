import { useScrollDirection } from '/machinery/useScrollDirection'

const NavigationContext = React.createContext(null)

export function NavigationContextProvider({ translations, children }) {
  const [menuIsExpanded, setMenuIsExpanded] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(null)
  const [mobileMenuIsExpanded, setMobileMenuIsExpanded] = React.useState(false)
  const [submenuHeight, setMenuBackgroundHeight] = React.useState(0)
  const [activeSubmenu, setActiveSubmenu] = React.useState({
    id: undefined,
    label: undefined,
    active: false,
    items: undefined,
  })

  const submenuIsOpen = activeSubmenu.active

  useScrollDirection({
    deltaThreshold: 50,
    scrollThreshold: 50,
    callback({ direction, metScrollThreshold }) {
      if (direction === 1) setMenuIsExpanded(false)
      else if (!metScrollThreshold) setMenuIsExpanded(true)
    }
  })

  return (
    <NavigationContext.Provider
      value={{
        translations,
        menuIsExpanded,
        setMenuIsExpanded,
        activeSubmenu,
        submenuIsOpen,
        onActiveSubmenuChange,
        mobileMenuIsExpanded,
        setMobileMenuIsExpanded,
        activeIndex,
        setActiveIndex,
        submenuHeight,
        setMenuBackgroundHeight
      }}
      {...{ children }}
    />
  )

  function onActiveSubmenuChange({ id, active, items = undefined, label = undefined }) {
    setActiveSubmenu({ id, active, label, items })
  }
}

export function useNavigationContext() {
  return React.useContext(NavigationContext)
}
