import { useFloating, useDismiss, useInteractions, useHover, autoUpdate, shift, useRole, useFocus, offset, safePolygon } from '@floating-ui/react'
import { useMediaQuery } from '@kaliber/use-media-query'

import { useTranslate } from '/machinery/I18n'

import { useNavigationContext } from '/features/pageOnly/menu/NavigationContext'
import { Hamburger } from '/features/pageOnly/menu/buildingBlocks/Hamburger'
import { Logo } from '/features/pageOnly/menu/buildingBlocks/Logo'
import { ExpandHorizontal } from '/features/buildingBlocks/Expand'
import { SubmenuDesktop } from '/features/pageOnly/menu/buildingBlocks/Submenu'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import { LanguageSwitch } from '/features/pageOnly/menu/buildingBlocks/LanguageSwitch'
import { ShowSkillsMatchPortalFlowButton } from '/features/pageOnly/skillsMatch/buildingBlocks/ShowSkillsMatchPortalFlowButton'

import mediaStyles from '/cssGlobal/media.css'
import styles from './MenuDesktop.css'

export function MenuDesktop({
  items,
  logoHref,
  showSkillsMatchButton,
  skillsMatchUserSelectionCount,
  pageButtons = [],
  fixedButtons = []
}) {
  const { menuIsExpanded } = useNavigationContext()

  return (
    <nav className={styles.component}>
      <div className={cx(styles.navBarContainer, menuIsExpanded && styles.menuIsExpanded)}>
        <NavBar
          layoutClassName={styles.navBarLayout}
          {...{
            items,
            logoHref,
            pageButtons,
            fixedButtons,
            showSkillsMatchButton,
            skillsMatchUserSelectionCount
          }}
        />
      </div>
    </nav>
  )
}

function NavBar({
  items,
  logoHref,
  pageButtons,
  fixedButtons,
  showSkillsMatchButton,
  skillsMatchUserSelectionCount,
  layoutClassName = undefined
}) {
  const { menuIsExpanded } = useNavigationContext()

  return (
    <div className={cx(styles.componentNavBar, layoutClassName)}>
      <Navigation layoutClassName={styles.navigationLayout} {...{ items, logoHref }} />
      <ActionMenu layoutClassName={styles.actionMenuLayout} {...{ pageButtons, fixedButtons, showSkillsMatchButton, skillsMatchUserSelectionCount }} />
      {menuIsExpanded && <Background layoutClassName={styles.backgroundLayout} />}
    </div>
  )
}

function Background({ layoutClassName = undefined }) {
  const { submenuHeight, activeSubmenu: { active } } = useNavigationContext()
  const height = active ? submenuHeight : 0

  return <span style={{ '--background-height': `${height}px` }} className={cx(styles.componentBackground, layoutClassName)} />
}

function Navigation({ items, logoHref, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentNavigation, layoutClassName)}>
      <div className={styles.logoContainer}>
        <Logo href={logoHref} layoutClassName={styles.logoLayout} />
      </div>
      <Menu layoutClassName={styles.menuLayout} {...{ items }} />
    </div>
  )
}

function Menu({ items, layoutClassName = undefined }) {
  const { menuIsExpanded } = useNavigationContext()

  return (
    <div className={cx(styles.componentMenu, menuIsExpanded && styles.menuIsExpanded, layoutClassName)}>
      <ul className={styles.menu}>
        {items.map((item, i) => (
          <li key={i} className={styles.menuItem}>
            <MenuItem layoutClassName={styles.itemLayout} {...{ item }} />
          </li>
        ))}
      </ul>
    </div>
  )
}

function MenuItem({ item, layoutClassName }) {
  switch (item?._type) {
    case 'referenceWithLabel': return <LinkItem {...{ item, layoutClassName }} />
    case 'submenu': return <SubmenuItem {...{ item, layoutClassName }} />
    default: return null
  }
}

function LinkItem({ item, layoutClassName = undefined }) {
  const { menuIsExpanded } = useNavigationContext()

  const { href, label, isActive } = item

  return (
    <a
      data-x='link-in-menu'
      tabIndex={menuIsExpanded ? 0 : -1}
      className={cx(
        styles.componentLinkItem,
        isActive && styles.isActive,
        layoutClassName
      )}
      {...{ href }}
    >
      <span className={styles.label}>{label}</span>
    </a>
  )
}

function SubmenuItem({ item, layoutClassName = undefined }) {
  const { menuIsExpanded, activeSubmenu } = useNavigationContext()
  const { refs, getReferenceProps, getItemProps, getFloatingProps } = useFloatingProps({
    id: item._key
  })

  const { _key: id, label, submenuItems } = item
  const thisSubmenuIsActive = id === activeSubmenu.id && activeSubmenu.active

  return (
    <>
      <button
        tabIndex={menuIsExpanded ? 0 : -1}
        {...getReferenceProps()}
        className={cx(
          styles.componentSubmenuItem,
          (thisSubmenuIsActive) && styles.isActive,
          layoutClassName
        )}
      >
        <span className={styles.label}>{label}</span>
      </button>

      {Boolean(thisSubmenuIsActive) && (
        <div className={styles.dropdown} {...getFloatingProps()}>
          <SubmenuDesktop
            items={submenuItems}
            isActive={thisSubmenuIsActive}
            layoutClassName={styles.submenuDesktopLayout}
            {...{ getItemProps }}
          />
        </div>
      )}
    </>
  )
}

function ActionMenu({
  pageButtons,
  fixedButtons,
  skillsMatchUserSelectionCount,
  showSkillsMatchButton,
  layoutClassName = undefined
}) {
  const { __ } = useTranslate()
  const isViewportXxl = useMediaQuery(mediaStyles.viewportXxl)
  const { translations, menuIsExpanded, setMenuIsExpanded } = useNavigationContext()

  return (
    <div className={cx(styles.componentActionMenu, layoutClassName)}>
      <ExpandHorizontal justification='end' expanded={!menuIsExpanded}>
        <div className={styles.contentSmall}>
          <Hamburger isTabbable={!menuIsExpanded} onClick={() => setMenuIsExpanded(true)} />
        </div>
      </ExpandHorizontal>

      <ExpandHorizontal expanded={menuIsExpanded}>
        <div className={styles.navigationButtons}>
          <LanguageSwitch
            isTabbable={menuIsExpanded}
            layoutClassName={styles.languageSwitchLayout}
            {...{ translations }}
          />

          {showSkillsMatchButton && (
            <ShowSkillsMatchPortalFlowButton
              buttonType='secondary'
              dataX='link-to-skillsmatch'
              tabIndex={menuIsExpanded ? 0 : -1}
              label={skillsMatchUserSelectionCount > 0 ? __`my-skills` : __`what-are-your-skills`}
            />
          )}

          {isViewportXxl && pageButtons?.map((x, i) => (
            <ButtonLinkPrimary
              key={i}
              href={x.href}
              label={x.label}
              dataX={x.dataX}
              tabIndex={menuIsExpanded ? 0 : -1}
              layoutClassName={styles.buttonLayout}
            />
          ))}
        </div>
      </ExpandHorizontal>

      {isViewportXxl && (
        <div className={styles.fixedButtons}>
          {fixedButtons?.map(({ href, label, dataX }, i) => (
            <ButtonLinkPrimary key={i} layoutClassName={styles.buttonLayout} {...{ href, label, dataX }} />
          ))}
        </div>
      )}
    </div>
  )
}

function useFloatingProps({ id }) {
  const { submenuHeight, activeSubmenu, onActiveSubmenuChange, setMenuBackgroundHeight } = useNavigationContext()
  const { y, strategy, refs, context, middlewareData } = useFloating({
    open: activeSubmenu.active && activeSubmenu.id === id,
    onOpenChange: handleActiveSubmenuChange,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      floatingElementSize(),
      shift({ padding: 0 }),
      offset(2)
    ]
  })

  const style = {
    position: strategy,
    top: y ?? 0,
    left: 0
  }

  const hover = useHover(context, { handleClose: safePolygon({ requireIntent: false }) })
  const focus = useFocus(context)
  const role = useRole(context, { role: 'menu' })
  const dismiss = useDismiss(context, {
    referencePress: false,
    outsidePress: false
  })

  const { getReferenceProps, getItemProps, getFloatingProps } = useInteractions([
    hover, focus, role, dismiss
  ])

  React.useEffect(
    () => {
      if (submenuHeight !== middlewareData?.floatingElementSize?.height) {
        setMenuBackgroundHeight(middlewareData?.floatingElementSize?.height || 0)
      }
    },
    [middlewareData?.floatingElementSize]
  )

  function handleActiveSubmenuChange(active) {
    onActiveSubmenuChange({ id, active })
  }

  return {
    refs,
    getReferenceProps: x => getReferenceProps({ ...x, ref: refs.setReference }),
    getFloatingProps: x => getFloatingProps({ ...x, ref: refs.setFloating, style }),
    getItemProps
  }
}

function floatingElementSize() {
  return {
    name: 'floatingElementSize',
    fn: ({ rects }) => ({
      data: {
        height: rects.floating.height
      }
    })
  }
}
